import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';


const styles = theme => ({
  dir: 'rtl',
    root: {
    '&:hover': {
      background: "$labelcolor",
    },
  
  },
  hover: {
    '&:hover': {
      backgroundColor: 'green !important',
    },
  },
  menuItem: {
    '&:focus': {
      backgroundColor: "$labelcolor",
      '& $primary, & $icon': {
      },
    },
  },
  primary: {},
  icon: {},
});



class Dropdown extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClickListItem = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuItemClick = (event, index) => {
    this.props.handleLanguageClick({language: this.props.languages[index].code})
    this.handleClose();
    document.activeElement.blur();
   };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes, theme,languages, label} = this.props;
    const { anchorEl } = this.state;
    const selectedIndex = languages.map((item) => { return item.code; }).indexOf(this.props.language.code);
    const Icon = languages[selectedIndex].icon;

    return (
 
      <li className={"hiddenItem" in this.props ? "hiddenItem" : "item"} key={label}
      >
      

      <div className={classes.root}>
        <List component="nav">
        <ListItem
            button
            aria-haspopup="true"
            aria-controls="lock-menu"
           aria-label={languages[selectedIndex].name}
            onClick={this.handleClickListItem}
          >
          <Icon />
         <ListItemText
        primary=
        {<li className="item" key={label}>
        <span>{languages[selectedIndex].name}</span>
        </li>}
            />
          </ListItem>
        </List>
        <Menu
          id="lock-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          language ={languages[selectedIndex]}
        >
          {languages.map((option, index) => (
            <MenuItem
              key={option}
              disabled={index === selectedIndex}
              selected={index === selectedIndex}
              onClick={event => this.handleMenuItemClick(event, index)}
            >

          <ListItemText
             primary={languages[index].name}
            />
          <ListItemIcon className={classes.icon}>
          <option.icon />
          </ListItemIcon>
          </MenuItem>
          ))}
        </Menu>

 {/* --- STYLES --- */}
 <style jsx>{`
        .item,
        .showItem {
          background: transparent;
          transition: all ${theme.time.duration.default};
          display: flex;
          align-items: center;

          :global(a) {
            padding: ${theme.space.inset.s};
            display: flex;
            align-items: center;
          }

          :global(svg) {
            margin: 0 ${theme.space.inset.xs} 0 0;
            opacity: 0.3;
            height: 20px;
            width: 20px;
          }
        }

        :global(.itemList .hideItem) {
          display: none;
        }

        @from-width desktop {
          .item {
            :global(a) {
              color: ${theme.text.color.primary};
              padding: ${theme.space.inset.s};
              transition: all ${theme.time.duration.default};
              border-radius: ${theme.size.radius.small};
            }

            :global(.homepage):not(.fixed) & :global(a) {
              color: ${theme.color.neutral.white};
            }

            :global(a:hover) {
              color: ${theme.color.brand.primary};
              background: color(white alpha(-60%));
            }

            :global(span) {
              color: ${theme.text.color.primary};
              padding: ${theme.space.inset.s};
              transition: all ${theme.time.duration.default};
              border-radius: ${theme.size.radius.small};
            }

            :global(.homepage):not(.fixed) & :global(span) {
              color: ${theme.color.neutral.white};
            }

            :global(span:hover) {
              color: ${theme.color.brand.primary};
            }

            :global(svg) {
              transition: all ${theme.time.duration.default};
            }

            &:hover :global(svg) {
              fill: ${theme.color.brand.primary};
              opacity: 1;

              :global(.hero) & :global(svg) {
                fill: green;
              }
            }
          }

          .showItem {
            display: none;
          }

          .hiddenItem {
            text-align: left;
            padding: ${theme.space.xs};

            & :global(a.inHiddenItem) {
              color: ${theme.text.color.primary};
              &:hover {
                color: ${theme.color.brand.primary};
              }
            }
          }
        }
      `}</style>
      </div>
      </li>

    );
  }
}

Dropdown.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Dropdown);

