// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-category-template-js": () => import("/opt/build/repo/src/templates/CategoryTemplate.js" /* webpackChunkName: "component---src-templates-category-template-js" */),
  "component---src-templates-post-template-js": () => import("/opt/build/repo/src/templates/PostTemplate.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-templates-page-template-js": () => import("/opt/build/repo/src/templates/PageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ar-404-js": () => import("/opt/build/repo/src/pages/ar/404.js" /* webpackChunkName: "component---src-pages-ar-404-js" */),
  "component---src-pages-ar-category-js": () => import("/opt/build/repo/src/pages/ar/category.js" /* webpackChunkName: "component---src-pages-ar-category-js" */),
  "component---src-pages-ar-contact-js": () => import("/opt/build/repo/src/pages/ar/contact.js" /* webpackChunkName: "component---src-pages-ar-contact-js" */),
  "component---src-pages-ar-index-js": () => import("/opt/build/repo/src/pages/ar/index.js" /* webpackChunkName: "component---src-pages-ar-index-js" */),
  "component---src-pages-ar-tech-stack-js": () => import("/opt/build/repo/src/pages/ar/techStack.js" /* webpackChunkName: "component---src-pages-ar-tech-stack-js" */),
  "component---src-pages-category-js": () => import("/opt/build/repo/src/pages/category.js" /* webpackChunkName: "component---src-pages-category-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-en-404-js": () => import("/opt/build/repo/src/pages/en/404.js" /* webpackChunkName: "component---src-pages-en-404-js" */),
  "component---src-pages-en-category-js": () => import("/opt/build/repo/src/pages/en/category.js" /* webpackChunkName: "component---src-pages-en-category-js" */),
  "component---src-pages-en-contact-js": () => import("/opt/build/repo/src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-index-js": () => import("/opt/build/repo/src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-tech-stack-js": () => import("/opt/build/repo/src/pages/en/techStack.js" /* webpackChunkName: "component---src-pages-en-tech-stack-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

