import React from 'react'
import { Container } from '../Container'
import { Wrapper, Flex, Links, Details } from './styles'
import social from './social.json'
import { FormattedMessage } from 'react-intl'

const Footer = props => {
	const {
		language
	}= props;
	return (
  
	<Wrapper>
		<Flex as={Container}>
			<Details>
				<h2> <FormattedMessage
                    id="DeveloperName"/></h2>
				<span>
				{new Date().getFullYear()} | 				<FormattedMessage
                    id="madeWith"/>{' '}
					<span aria-label="love" role="img">
						💖
					</span>{' '}
					<FormattedMessage
                    id="by"/>{' '}
					<a
						href={`https://sulimanalruz.com/${language.code}/about`}
						rel="noopener noreferrer"
						target="_blank"
					>
						 <FormattedMessage
                    id="DeveloperName"/>
					</a>
				</span>
			</Details>
			<Links>
				{social.map(({ id, name, link, icon }) => (
					<a
						key={id}
						href={link}
						target="_blank"
						rel="noopener noreferrer"
						aria-label={`follow me on ${icon}`}
					>
						<img className="socialIcon" width="24" src={icon} alt={name} title={name} />
					</a>
				))}
			</Links>
		</Flex>
	</Wrapper>
	);
};

export default Footer;
